<template>
  <div id="orderIn">
    <el-dialog
      :title="orderInFormTitle"
      width="1200px"
      :visible.sync="orderInDialogVisible"
      :close-on-click-modal="false"
      @close="orderInDialogClose"
    >
      <el-form
        ref="orderInFormRef"
        :model="orderInForm"
        :rules="orderInFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="申请部门" prop="applyDept">
              <el-select
                v-model="orderInForm.applyDept"
                placeholder="请选择申请部门"
                clearable
                :disabled="orderInFormTitle !== '新增入库单'
                  && orderInFormTitle !== '修改入库单'
                  && orderInFormTitle !== '入库单详情'"
                @change="deptChange"
              >
                <el-option
                  v-for="item in ['生产设备部', '质量部', '采购部', '仓储部', '销售部']"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="编号" prop="serialNumber">
              <el-input
                v-model="orderInForm.serialNumber"
                placeholder="请输入编号"
                clearable
                :disabled="orderInFormTitle !== '新增入库单'
                  && orderInFormTitle !== '修改入库单'
                  && orderInFormTitle !== '入库单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请日期" prop="applyDate">
              <el-date-picker
                v-model="orderInForm.applyDate"
                placeholder="请选择申请日期"
                value-format="yyyy-MM-dd"
                :disabled="orderInFormTitle !== '新增入库单'
                  && orderInFormTitle !== '修改入库单'
                  && orderInFormTitle !== '入库单详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar
            v-if="orderInFormTitle === '新增入库单'
              || orderInFormTitle === '修改入库单'"
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="detailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column field="materialNo" title="物料编号">
              <template v-slot="{row, column}">
                <el-select
                  v-model="row[column.property]"
                  placeholder="请选择物料"
                  clearable
                  filterable
                  @change="materialStockChange(row)"
                >
                  <el-option
                    v-for="item in materialStockList"
                    :key="item.no"
                    :label="item.no"
                    :value="item.no"
                  >
                    <span style="float: left">{{ item.no }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.name }}</span>
                  </el-option>
                </el-select>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="materialName"
              title="物料名称"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="spec"
              title="规格型号"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="batchNo"
              title="批号"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="quantity"
              title="数量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="situationExplain"
              title="入库情况说明"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              v-if="orderInFormTitle === '新增入库单'
                || orderInFormTitle === '修改入库单'"
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="审核结果" prop="reviewerResult">
              <el-radio-group
                v-model="orderInForm.reviewerResult"
                :disabled="orderInFormTitle !== '审核入库单'
                  && orderInFormTitle !== '入库单详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确认结果" prop="confirmerResult">
              <el-radio-group
                v-model="orderInForm.confirmerResult"
                :disabled="orderInFormTitle !== '确认入库单'
                  && orderInFormTitle !== '入库单详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="orderInDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="orderInFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="编号">
        <el-input v-model="searchForm.serialNumber" placeholder="请输入编号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['ORDER_IN_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="orderInPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column prop="serialNumber" label="编号" />
      <el-table-column prop="applyDept" label="申请部门" />
      <el-table-column label="申请日期">
        <template slot-scope="scope">
          <span v-if="scope.row.applyDate">{{ scope.row.applyDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="editor" label="编制人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.editorDate">{{ scope.row.editorDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="审核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerDate">{{ scope.row.reviewerDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerResult === 1">通过</span>
          <span v-if="scope.row.reviewerResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column prop="confirmer" label="确认人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.confirmerDate">{{ scope.row.confirmerDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="确认结果">
        <template slot-scope="scope">
          <span v-if="scope.row.confirmerResult === 1">通过</span>
          <span v-if="scope.row.confirmerResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['ORDER_IN_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['ORDER_IN_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['ORDER_IN_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            审核
          </el-button>
          <el-button
            v-if="checkPermission(['ORDER_IN_CONFIRM']) && scope.row.status === 2"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleConfirm(scope.$index, scope.row)"
          >
            确认
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="orderInPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addOrderIn,
  deleteOrderIn,
  updateOrderIn,
  selectOrderInInfo,
  selectOrderInList,
  reviewOrderIn,
  confirmOrderIn
} from '@/api/universal/orderIn'
import { selectMaterialPurchaseStockList } from '@/api/storage/materialPurchaseStock'
import { selectMaterialProductStockList } from '@/api/storage/materialProductStock'
import moment from 'moment'

export default {
  data () {
    return {
      orderInDialogVisible: false,
      orderInFormTitle: '',
      orderInForm: {
        id: '',
        serialNumber: '',
        applyDept: '',
        applyDate: '',
        reviewerResult: '',
        confirmerResult: '',
        detailJson: '',
        taskId: ''
      },
      orderInFormRules: {
        serialNumber: [{ required: true, message: '编号不能为空', trigger: ['blur', 'change']}]
      },
      orderInPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        serialNumber: ''
      },
      detailList: [],
      materialStockList: []
    }
  },
  created () {
    selectOrderInList(this.searchForm).then(res => {
      this.orderInPage = res
    })
    selectMaterialPurchaseStockList({ type: 1 }).then(res => {
      for (let value of res.list) {
        this.materialStockList.push({
          no: value.materialNo,
          name: value.productName,
          spec: value.spec
        })
      }
      selectMaterialProductStockList({ type: 1 }).then(res => {
        for (let value of res.list) {
          this.materialStockList.push({
            no: value.productNo,
            name: value.productName,
            spec: value.spec
          })
        }
      })
    })
  },
  methods: {
    orderInDialogClose () {
      this.$refs.orderInFormRef.resetFields()
      this.detailList = []
    },
    orderInFormSubmit () {
      if (this.orderInFormTitle === '入库单详情') {
        this.orderInDialogVisible = false
        return
      }
      this.$refs.orderInFormRef.validate(async valid => {
        if (valid) {
          if (this.orderInFormTitle === '新增入库单') {
            this.orderInForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addOrderIn(this.orderInForm)
          } else if (this.orderInFormTitle === '修改入库单') {
            this.orderInForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateOrderIn(this.orderInForm)
          } else if (this.orderInFormTitle === '审核入库单') {
            await reviewOrderIn(this.orderInForm)
          } else if (this.orderInFormTitle === '确认入库单') {
            await confirmOrderIn(this.orderInForm)
          }
          this.orderInPage = await selectOrderInList(this.searchForm)
          this.orderInDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.orderInFormTitle = '新增入库单'
      this.orderInDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteOrderIn(row.id)
        if (this.orderInPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.orderInPage = await selectOrderInList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.orderInFormTitle = '修改入库单'
      this.orderInDialogVisible = true
      this.selectOrderInInfoById(row.id)
    },
    handleReview (index, row) {
      this.orderInFormTitle = '审核入库单'
      this.orderInDialogVisible = true
      this.selectOrderInInfoById(row.id)
    },
    handleConfirm (index, row) {
      this.orderInFormTitle = '确认入库单'
      this.orderInDialogVisible = true
      this.selectOrderInInfoById(row.id)
    },
    handleInfo (index, row) {
      this.orderInFormTitle = '入库单详情'
      this.orderInDialogVisible = true
      this.selectOrderInInfoById(row.id)
    },
    selectOrderInInfoById (id) {
      selectOrderInInfo(id).then(res => {
        this.orderInForm.id = res.id
        this.orderInForm.serialNumber = res.serialNumber
        this.orderInForm.applyDept = res.applyDept
        this.orderInForm.applyDate = res.applyDate
        this.orderInForm.reviewerResult = res.reviewerResult
        this.orderInForm.confirmerResult = res.confirmerResult
        this.orderInForm.taskId = res.taskId
        this.detailList = res.detailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectOrderInList(this.searchForm).then(res => {
        this.orderInPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectOrderInList(this.searchForm).then(res => {
        this.orderInPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectOrderInList(this.searchForm).then(res => {
        this.orderInPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['ORDER_IN_REVIEW']) && row.status === 1) {
        return 'review'
      } else if (this.checkPermission(['ORDER_IN_CONFIRM']) && row.status === 2) {
        return 'review'
      }
      return ''
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    deptChange (value) {
      if (value === '生产设备部') {
        this.orderInForm.serialNumber = 'R' + moment().format('YYYYMMDD') + '-SCSB-'
      } else if (value === '质量部') {
        this.orderInForm.serialNumber = 'R' + moment().format('YYYYMMDD') + '-ZL-'
      } else if (value === '采购部') {
        this.orderInForm.serialNumber = 'R' + moment().format('YYYYMMDD') + '-CG-'
      } else if (value === '仓储部') {
        this.orderInForm.serialNumber = 'R' + moment().format('YYYYMMDD') + '-CC-'
      } else if (value === '销售部') {
        this.orderInForm.serialNumber = 'R' + moment().format('YYYYMMDD') + '-XS-'
      } else {
        this.orderInForm.serialNumber = ''
      }
    },
    materialStockChange (row) {
      console.log(row)
      if (row.materialNo) {
        let materialStock = this.materialStockList.find(item => item.no === row.materialNo)
        row.materialName = materialStock.name
        row.spec = materialStock.spec
      } else {
        row.materialName = ''
        row.spec = ''
      }
    }
  }
}
</script>

<style>
</style>
